import React from "react";
import {
  Card,
  Divider,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";

import { PieChart } from "react-minimal-pie-chart";

class DashboardDemo extends React.Component {
  state = { logs: [] };

  handleError = (errorObj) => {
    let error = this.containsError(errorObj)
      ? errorObj
      : { message: "Something Went Wrong" };
    if (error.error) {
      alert(`${error.error}`);
    }
  };

  containsError = (data) => "error" in data;

  handleApiResponse = (data) => {
    if (this.containsError(data)) {
      return Promise.reject(data);
    }
    return data;
  };

  getLogs = () => {
    const URL = this.props.baseEndpoint() + `logs`;
    const options = {
      method: "GET",
    };
    return fetch(URL, options)
      .then((resp) => resp.json())
      .then(this.handleApiResponse)
      .catch(this.handleError);
  };

  refresh = () => {
    this.getLogs().then((logs) => this.setState({ logs: logs }));
    setTimeout(this.refresh, 5000);
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <div style={{ marginRight: "25px", marginTop: "15px" }}>
            <Header size="large" style={{ marginLeft: "0px" }}>
              Realtime Entry Log
            </Header>
            <Card style={{ width: "300px", height: "60px" }}>
              <Card.Content>
                <Header
                  color="grey"
                  style={{ display: "inline-block" }}
                  as="h4"
                >
                  <Icon
                    color={"grey"}
                    name="arrow alternate circle right"
                  ></Icon>
                  ROA Member Entry
                </Header>

                <h1
                  style={{
                    display: "inline-block",
                    margin: "0px",
                    float: "right",
                    color: "#EB7D72",
                  }}
                >
                  25%
                </h1>
              </Card.Content>
            </Card>
            <Card style={{ width: "300px", height: "60px" }}>
              <Card.Content>
                <Header
                  color="grey"
                  style={{ display: "inline-block" }}
                  as="h4"
                >
                  <Icon color={"grey"} name="group"></Icon>
                  Members Cancelled
                </Header>

                <h1
                  style={{
                    display: "inline-block",
                    margin: "0px",
                    float: "right",
                    color: "#A7A7A8",
                  }}
                >
                  35%
                </h1>
              </Card.Content>
            </Card>
            <Card style={{ width: "300px", height: "60px" }}>
              <Card.Content>
                <Header
                  color="grey"
                  style={{ display: "inline-block" }}
                  as="h4"
                >
                  <Icon color={"grey"} name="wait"></Icon>
                  Members Late
                </Header>

                <h1
                  style={{
                    display: "inline-block",
                    margin: "0px",
                    float: "right",
                    color: "#29323B",
                  }}
                >
                  40%
                </h1>
              </Card.Content>
            </Card>
            <Card style={{ width: "300px" }}>
              <PieChart
                viewBoxSize={[90, 90]}
                radius={40}
                center={[45, 45]}
                animate
                data={[
                  { title: "Late", value: 40, color: "#29323B" },
                  { title: "Entered", value: 35, color: "#A7A7A8" },
                  { title: "Cancelled", value: 25, color: "#EB7D72" },
                ]}
              />
            </Card>
          </div>
          <Segment>
            <List
              className="example"
              relaxed
              animated
              style={{ overflowY: "scroll", height: "535px", width: "600px" }}
            >
              <Header as="h3">Today</Header>
              <Divider></Divider>

              {this.state.logs.map((log) => (
                <List.Item style={{ marginBottom: "7px" }}>
                  <Image
                    avatar
                    src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/glog8kw0ecdvyoci4ipp"
                  />

                  <List.Content>
                    <List.Header as="a">{log.text}</List.Header>
                    <List.Description>
                      {new Date(log.created_at).toDateString()} -{" "}
                      {new Date(log.created_at).toTimeString()}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </div>
      </>
    );
  }
}

export default DashboardDemo;
