import React from "react";
import { Card, Image, List } from "semantic-ui-react";

class Dashboard extends React.Component {
  state = { logs: [] };

  handleError = (errorObj) => {
    let error = this.containsError(errorObj)
      ? errorObj
      : { message: "Something Went Wrong" };
    if (error.error) {
      alert(`${error.error}`);
    }
  };

  containsError = (data) => "error" in data;

  handleApiResponse = (data) => {
    if (this.containsError(data)) {
      return Promise.reject(data);
    }
    return data;
  };

  getLogs = () => {
    const URL = this.props.baseEndpoint() + `logs`;
    const options = {
      method: "GET",
    };
    return fetch(URL, options)
      .then((resp) => resp.json())
      .then(this.handleApiResponse)
      .catch(this.handleError);
  };

  refresh = () => {
    this.getLogs().then((logs) => this.setState({ logs: logs }));
    setTimeout(this.refresh, 5000);
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <List relaxed animated>
        {this.state.logs.map((log) => (
          <List.Item>
            <Image
              avatar
              src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/glog8kw0ecdvyoci4ipp"
            />

            <List.Content>
              <List.Header as="a">{log.text}</List.Header>
              <List.Description>
                {new Date(log.created_at).toDateString()} -{" "}
                {new Date(log.created_at).toTimeString()}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  }
}

export default Dashboard;
