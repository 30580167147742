import React from "react";
import PassCreator from "./PassCreator";
import Dashboard from "./Dashboard";
import SecretDashboard from "./Dashboard2";
import DashboardDemo from "./DashboardDemo";
import GovDemo from "./GovDemo";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const baseEndpoint = () => {
  return process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://api.passentry.com/";
};

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route path="/dashboard">
            <Dashboard baseEndpoint={baseEndpoint} />
          </Route>
          <Route path="/dashboard-demo">
            <DashboardDemo baseEndpoint={baseEndpoint} />
          </Route> */}
          {/* {process.env.REACT_APP_SECRET_URL && (
            <Route path={"/" + process.env.REACT_APP_SECRET_URL}>
              <SecretDashboard baseEndpoint={baseEndpoint} />
            </Route>
          )} */}
          <Route path={"/dashboard/scc"}>
            <SecretDashboard baseEndpoint={baseEndpoint} />
          </Route>
          <Route path={"/dashboard-demo"}>
            <DashboardDemo baseEndpoint={baseEndpoint} />
          </Route>
          <Route path={"/covid"}>
            <GovDemo baseEndpoint={baseEndpoint} />
          </Route>
          <Route
            path="/o/:id"
            component={(routerProps) => (
              <PassCreator
                organisationId={routerProps.match.params.id}
                {...routerProps}
                baseEndpoint={baseEndpoint}
              />
            )}
          />
          <Route
            path="/"
            component={(routerProps) => (
              // <PassCreator
              //   organisationId={"SurreyCoffee"}
              //   {...routerProps}
              //   baseEndpoint={baseEndpoint}
              // />\
              <GovDemo baseEndpoint={baseEndpoint} />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
