import React from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Image,
  Input,
  List,
  Loader,
  Modal,
} from "semantic-ui-react";
import { CSVLink } from "react-csv";

class Dashboard extends React.Component {
  state = { logs: [], password: undefined, passwordInput: "" };

  handleError = (errorObj) => {
    let error = this.containsError(errorObj)
      ? errorObj
      : { message: "Something Went Wrong" };
    if (error.error) {
      alert(`${error.error}`);
    }
  };

  containsError = (data) => "error" in data;

  handleApiResponse = (data) => {
    if (this.containsError(data)) {
      return Promise.reject(data);
    }
    return data;
  };

  generateCSVdata = () => {
    const new_array = [];
    new_array.push([
      "Name",
      "Email",
      "Last_Tap",
      "Stamps",
      "Free_Items",
      "Sign_Up",
    ]);
    this.state.logs.forEach((log) =>
      new_array.push([
        log.name,
        log.email,
        log.last_tap
          ? new Date(log.last_tap?.created_at).toLocaleString()
          : "-",
        log.stamp_total,
        log.free_items,
        new Date(log.created_at).toLocaleString(),
      ])
    );
    return new_array;
  };

  getLogs = (password) => {
    const URL = this.props.baseEndpoint() + password;
    const options = {
      method: "POST",
      body: JSON.stringify({ id: "SurreyCoffee" }),
      headers: {
        "Content-type": "application/json",
      },
    };
    return fetch(URL, options)
      .then((resp) => resp.json())
      .then(this.handleApiResponse)
      .catch(this.handleError);
  };

  // refresh = () => {
  //   this.getLogs().then((logs) => this.setState({ logs: logs }));
  //   setTimeout(this.refresh, 50000);
  // };

  // componentDidMount() {
  //   this.refresh();
  // }

  render() {
    return (
      // <List relaxed animated>
      //   {this.state.logs.map((log) => (
      //     <List.Item>
      //       <Image
      //         avatar
      //         src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/glog8kw0ecdvyoci4ipp"
      //       />

      //       <List.Content>
      //         <List.Header as="a">{log.text}</List.Header>
      //         <List.Description>
      //           {new Date(log.created_at).toDateString()} -{" "}
      //           {new Date(log.created_at).toTimeString()}
      //         </List.Description>
      //       </List.Content>
      //     </List.Item>
      //   ))}
      // </List>
      <Grid textAlign="center" columns={7} padded>
        {!!this.state.logs.length && (
          <CSVLink data={this.generateCSVdata()} target="_blank">
            Download CSV
          </CSVLink>
        )}
        <Loader active={this.state.logs.length === 0} />
        <Modal style={{ width: "450px" }} open={!this.state.password}>
          <div style={{ display: "flex", width: "100%" }}>
            <Input
              value={this.state.passwordInput}
              onChange={(e) => this.setState({ passwordInput: e.target.value })}
              style={{ minWidth: "400px" }}
              fluid
            ></Input>
            <Button
              onClick={(e) => {
                this.setState({ password: this.state.passwordInput });
                this.getLogs(this.state.passwordInput).then((logs) =>
                  this.setState({ logs: logs })
                );
              }}
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Grid.Row color="grey">
          <Grid.Column>
            <Header color="black" as="h4">
              Name
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Email
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Last Tap
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Last Tap Location
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Total Stamps
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Free Items
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header color="black" as="h4">
              Sign Up Date
            </Header>
          </Grid.Column>
        </Grid.Row>
        {this.state.logs
          .sort((a, b) => {
            return b.stamp_total - a.stamp_total;
          })
          .map((l) => (
            <Grid.Row>
              <Grid.Column>{l.user && l.user.name}</Grid.Column>
              <Grid.Column>{l.user && l.user.email}</Grid.Column>
              <Grid.Column>
                {l.last_tap
                  ? new Date(l.last_tap?.created_at).toLocaleString()
                  : "-"}
              </Grid.Column>
              <Grid.Column>{l.last_tap_reader}</Grid.Column>
              <Grid.Column>{l.stamp_total}</Grid.Column>
              <Grid.Column>{l.free_items}</Grid.Column>
              <Grid.Column>
                {new Date(l.created_at).toLocaleString()}
              </Grid.Column>
            </Grid.Row>
          ))}
      </Grid>
    );
  }
}

export default Dashboard;
