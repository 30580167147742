import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Input,
  Button,
  Segment,
  Form,
  Header,
  Divider,
  Image,
  Progress,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { SketchPicker, CompactPicker } from "react-color";
import ColorPicker from "./ColorFile";
import appleAdd from "./apple.svg";
import googleAdd from "./google.svg";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

// const baseEndpoint = () => {
//   return process.env.NODE_ENV === "development"
//     ? "http://localhost:3000/"
//     : "https://api.passentry.com/";
// };

//https://api.passentry.com/
// const GooglePay = (props) => {
//   return React.createElement("g:savetoandroidpay", props)
// }

// const baseEndpoint = () => "https://passentry-surrey.herokuapp.com/"

class PassCreator extends React.Component {
  state = {
    organisation: null,
    url: null,
    logs: [],
    email: "goverment@demo.com",
    name: "",
    backgroundColor: "#efede7",
    labelColor: "#c0a37c",
    percentProcess: 0,
  };

  componentDidMount() {
    this.randomizeSerial();

    fetch(this.props.baseEndpoint() + "organisations/gov", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((o) => this.setState({ organisation: o }));
  }

  randomizeSerial = () => {
    let newPass = { ...this.state.pass };
    newPass["serialNumber"] = Math.floor(Math.random() * 1000000).toString();
    this.setState({ pass: newPass });
    return Math.floor(Math.random() * 1000000).toString();
  };

  updateSecondaryValue2 = (event) => {
    let newPass = { ...this.state.pass };
    newPass["storeCard"]["secondaryFields"][1]["value"] = event.target.value;
    this.setState({ pass: newPass, percentProcess: 33 });
  };

  handleChangeBackground = (color) => {
    let newPass = { ...this.state.pass };
    newPass["backgroundColor"] = color.hex;
    this.setState({ pass: newPass, backgroundColor: color.hex });
  };

  handleChangeLabel = (color) => {
    let newPass = { ...this.state.pass };
    newPass["labelColor"] = color.hex;
    this.setState({ pass: newPass, labelColor: color.hex });
  };

  postApplePass = () => {
    this.randomizeSerial();
    this.setState({ percentProcess: 100 });
    fetch(this.props.baseEndpoint() + "passes", {
      method: "POST",
      responseType: "blob",
      body: JSON.stringify({
        name: this.randomizeSerial(),
        email: this.state.email,
        organisationId: this.state.organisation.id,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LoyaltyCard.pkpass");
        document.body.appendChild(link);
        link.click();
      });
  };

  postGooglePass = () => {
    this.randomizeSerial();
    this.setState({ percentProcess: 100 });
    fetch(this.props.baseEndpoint() + "create_google_pass", {
      method: "POST",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        organisationId: this.state.organisation.id,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((response) => {
        // this.setState({ url: response["url"] });
        window.location.href = response["url"];
      });
  };

  render() {
    return (
      <div className="App">
        {/* <Segment
          style={{
            backgroundColor: "#0A0B0B",
            height: "52px",
            padding: "5px",
          }}
        >
          <Image
            size="small"
            src="https://uploads-ssl.webflow.com/5df785e26482d831b637d003/5ea1ed3f10f64936fcd0a99e_Logo%20on%20dark_1.svg"
          />
        </Segment> */}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Segment
            basic
            color="black"
            style={{
              backgroundColor: "#0A0B0B",
              minWidth: "100vw",
              minHeight: "100vh",
              position: "absolute",
              margin: "auto",
            }}
          >
            {this.state.organisation ? (
              <Image
                style={{ margin: "auto", marginTop: "25vh" }}
                size="medium"
                src="https://i.ibb.co/jhzCKVr/opengraph-image-a1f7d89ffd0782738b1aeb0da37842d8bd0addbd724b8e58c3edbc7287cc11de.png"
              ></Image>
            ) : (
              <Dimmer inverted active>
                <Loader />
              </Dimmer>
            )}
            <Form>
              {!isAndroid && (
                <Image
                  size="small"
                  src={appleAdd}
                  style={{
                    margin: "auto",
                    marginTop: "30px",
                    marginBottom: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.postApplePass()}
                ></Image>
              )}
              {isAndroid && (
                <Image
                  src={googleAdd}
                  fluid
                  onClick={() => this.postGooglePass()}
                  style={{
                    margin: "auto",
                    marginTop: "10px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                ></Image>
              )}
            </Form>
          </Segment>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100vw",
            padding: "2px",
          }}
          attached="bottom"
        >
          <span style={{ margin: "auto", backgroundColor: "red" }}>
            This site is a Demo / Proof of Concept and is not affiliated with
            the UK Government
          </span>
        </div>
      </div>
    );
  }
}

export default PassCreator;
